import React, { useEffect } from "react";
import "./HeroSection.css";
import { Button } from "./Button";
import { useSpring, animated } from "react-spring";
import Aos from "aos";
import "aos/dist/aos.css";

function returnFade(str) {
  if (str) {
    return "fade-right";
  } else {
    return "fade-left";
  }
}
function HeroSection({
  lightBg,
  topLine,
  lightText,
  lightTextDesc,
  headline,
  description,
  buttonLabel,
  img,
  alt,
  imgStart,
}) {
  const props = useSpring({
    from: { opacity: 0, marginTop: -150 },
    to: { opacity: 1, marginTop: 0 },
  });
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <>
      <div
        className={lightBg ? "home__hero-section" : "home__hero-section darkBg"}
      >
        <div className="container">
          <div
            className="row home__hero-row"
            style={{
              display: "flex",
              flexDirection: imgStart === "start" ? "row-reverse" : "row",
            }}
          >
            <div className="col" data-aos={returnFade(lightText)}>
              <div className="home__hero-text-wrapper">
                <div className="top-line">{topLine}</div>
                <h1 className={lightText ? "heading" : "heading dark"}>
                  {headline}
                </h1>
                <p
                  className={
                    lightTextDesc
                      ? "home__hero-subtitle"
                      : "home__hero-subtitle dark"
                  }
                >
                  {description}
                </p>
              </div>
            </div>
            <div className="col">
              <div className="home__hero-img-wrapper" data-aos="fade-up">
                <img
                  style={{
                    maxWidth: "70%",
                    borderRadius: "40px",
                    marginLeft: "7rem",
                  }}
                  src={img}
                  alt={alt}
                  className="home__hero-img"
                />
                <p
                  className={
                    lightTextDesc
                      ? "home__hero-subtitle"
                      : "home__hero-subtitle dark"
                  }
                  style={{
                    textAlign: 'center',
                    marginLeft: "6rem",
                    marginTop: '10px',
                    fontSize: '1.75vh'
                    
                  }}
                >
                  Rudy (left) and Sarthak (right)
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
