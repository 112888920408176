import React from 'react';
import ContactPage from '../../contactPage';
import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour } from './Data';
import Pricing from '../../Pricing';

function SignUp() {
  return (
    <div>
      <ContactPage {...homeObjOne} />
    </div>
  );
}

export default SignUp;
