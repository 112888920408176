export const homeObjOne = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Contracting made easy",
  headline: "Fast. Intuitive. Simple.",
  description:
    "Workbee provides an intuitive solution to working with unreliable contractors by uberizing the holidays. Homeowners can find, review, schedule, and pay for holiday light installation services effeciently with trusted contractors.",
  buttonLabel: "badges",
  imgStart: "",
  img: "images/newSS/homeOrderInfo.png",
  alt: "Credit Card",
};

export const homeObjTwo = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "ACCOMMODATES ALL YOUR NEEDS",
  headline: "Multiple Personalized Services.",
  description:
    "Workbee offers multiple personalized services for you to select from. We are opartnered with a local franchise of AlphaGraphics and 5 certified contracting teams.",
  buttonLabel: "",
  imgStart: "",
  img: "images/newSS/placeOrderHome.png",
  alt: "Vault",
};

export const homeObjThree = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "100% SECURE",
  headline: "Secure by design.",
  description:
    "WorkBee communicates directly with a secure server and all transactions are handled through Stripe. This means are payment system is fully PCI compliant and we never even see your credit card information.",
  buttonLabel: "",
  imgStart: "start",
  img: "images/svg-4.svg",
  alt: "Vault",
};

export const homeObjFour = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "THE NEW WAY TO HOLIDAY",
  headline: "Simple Login.",
  description:
    "WorkBee Keeps your orders syncronized between devices and secures your information. WorkBee also provides you the option to login with either Apple or Google for an instant setup.",
  buttonLabel: "",
  imgStart: "start",
  img: "images/newSS/login.png",
  alt: "Vault",
};

export const homeObjFive = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "YOUR TIME IS VALUED",
  headline: "Specify your exact needs.",
  description:
    "WorkBee allows you to place an order without the need for tedious and time consuming in-person consultations.",
  buttonLabel: "",
  imgStart: "start",
  img: "images/newSS/fields.png",
  alt: "Vault",
};

export const homeObjSix = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "CONTRACTORS KNOW YOUR EXACT NEEDS",   
  headline: "House Highlighting: Our Signature feature",
  description: "WorkBee allows you to sketch where you want the lights installed on your home, allowing contractors to give you a more accurate quote and higher quality installation. ",
  buttonLabel: "",
  imgStart: "",
  img: "images/newSS/drawHouse.png",
  alt: "Vault",
};
export const homeObjSeven = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "VIEW REALTIME FEEDBACK ON CONTRACTORS",
  headline: "Work With the Best.",
  description:
    "WorkBee only works with contractors that have a reliable, long standing relationship within the community. You can ensure satisfaction by reading reviews on the quality of installations and contractors' abilities.",
  buttonLabel: "",
  imgStart: "start",
  img: "images/newSS/reviews.png",
  alt: "Vault",
};
export const homeObjEight = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "THE FUTURE OF CONTRACTING",
  headline: "Talk To Our Intelligent Chat Bot.",
  description:
    "WorkBee provides you with the option to talk to a FAQ Chat Bot powered by a NLP model trained with over 100 customized responses about WorkBee's process. ",
  buttonLabel: "",
  imgStart: "",
  img: "images/newSS/chatbot.png",
  alt: "Vault",
};
export const homeObjNine = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "stay informed",
  headline: "Talk To Our Intelligent Chat Bot.",
  description:
    "Search through 300+ courses that FriscoISD has to offer. View detailed course description and credit information. Uses a dynamic search engine to sort by name, description, and other relevant course information.",
  buttonLabel: "",
  imgStart: "start",
  img: "images/courseCatalog.png",
  alt: "Vault",
};
export const homeObjTen = {
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: "Personalize your experience",
  headline: "Customize Color Scheme.",
  description:
    "Customize the app by choosing between 12 different color schemes.",
  buttonLabel: "",
  imgStart: "",
  img: "images/colorCustomize.png",
  alt: "Vault",
};
