import React, {useEffect} from 'react';
import './HeroSection.css';
import { Button } from './Button';
import {useSpring, animated} from 'react-spring';
import Aos from 'aos';
import 'aos/dist/aos.css';

function returnButton(str){
  if(str == 'badges'){
      return (
        <div className="badge-row">
          <a
            href="https://apps.apple.com/us/app/workbee-simple-contracting/id1555771830"
            target="blank"
          >
            <img src={"images/apple.svg"} alt={"hey"} className="badge-apple" />
          </a>
          <a
            href="https://apps.apple.com/us/app/workbee-simple-contracting/id1555771830"
            target="blank"
          >
            <img
              src={"images/google.svg"}
              alt={"hey"}
              className="badge-google"
            />
          </a>
        </div>
      );
  }
  else if(str != '') {
    return (
      <a
      // href="https://play.google.com/store/apps/details?id=com.gradify"
      target="blank"
    >
      <Button buttonSize='btn--wide' buttonColor='blue'>
          {str}
        </Button>
    </a>
    )
  }
}

function returnFade(str) {
  if(str){
    return 'fade-right';
  }
  else {
    return 'fade-left';
  }
}
function HeroSection({
  lightBg,
  topLine,
  lightText,
  lightTextDesc,
  headline,
  description,
  buttonLabel,
  img,
  alt,
  imgStart
}) {
const props = useSpring({
  from: {opacity: 0, marginTop: -150},
  to: {opacity: 1, marginTop: 0}
})
useEffect(() => {
  Aos.init({duration: 2000});
}, []);
  return (
    <>
      <div

        className={lightBg ? 'home__hero-section' : 'home__hero-section darkBg'}
      >
        <div className='container'>
          <div
            className='row home__hero-row'
            style={{
              display: 'flex',
              flexDirection: imgStart === 'start' ? 'row-reverse' : 'row'
            }}
          >
            <div className='col' 
            data-aos={returnFade(lightText)}
            >
              <div className='home__hero-text-wrapper'>
                <div className='top-line'>{topLine}</div>
                <h1 className={lightText ? 'heading' : 'heading dark'}>
                  {headline}
                </h1>
                <p
                  className={
                    lightTextDesc
                      ? 'home__hero-subtitle'
                      : 'home__hero-subtitle dark'
                  }
                >
                  {description}
                </p>
                {returnButton(buttonLabel)}
              </div>
            </div>
            <div className='col'>
              <div className='home__hero-img-wrapper' data-aos="fade-up">
                <img src={img} alt={alt} className='home__hero-img' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HeroSection;
