import React from 'react';
import HeroSection from '../../HeroSection';
import { homeObjOne, homeObjTwo, homeObjThree, homeObjFour, homeObjFive, homeObjSix, homeObjSeven, homeObjEight, homeObjNine, homeObjTen } from './Data';
import Pricing from '../../Pricing';

function Home() {
  return (
    <>
      <HeroSection {...homeObjOne} />
      <HeroSection {...homeObjFour} />
      <HeroSection {...homeObjTwo} />
      <HeroSection {...homeObjFive} />
      <HeroSection {...homeObjSix} />
      <HeroSection {...homeObjSeven} />
      <HeroSection {...homeObjEight} />
      {/* <HeroSection {...homeObjNine} />
      <HeroSection {...homeObjTen} /> */}
      <HeroSection {...homeObjThree} />
    </>
  );
}

export default Home;
